






































import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppBarTab } from "@/models/utils/c-tab.interface";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import TabModule from "@/store/modules/tab.module";
const tabModule = getModule(TabModule, store);

const CJobCard = () =>
  import(/* webpackChunkName: "CJobCard" */ "./components/CJobCard.vue");

@Component({
  components: {
    "c-job-card": CJobCard,
  },
})
export default class CJobs extends Vue {
  @Prop({ required: false, default: null, type: Number })
  private currenCalendarJobId: number;
  @Prop({ required: false, default: null, type: Array })
  private jobs: CalendarJob[];

  get tab(): AppBarTab {
    return tabModule.current;
  }
}
